import React from "react"

import Layout from "~/components/common/Layout"
import { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import { Text, Ul } from "~/components/common/Typography"
import HeroVideo from "~/components/page/HeroVideo"
import { graphql, useStaticQuery } from "gatsby"
import { Infobox, InfoboxDetailed } from "~/components/page/Infobox"
import { Box, Flex } from "reflexbox"
import Img from "gatsby-image"
import Copyright from "~/components/common/Copyright"
import Source from "~/components/common/Source"
import CustomPlayer from "~/components/common/CustomPlayer"

const Page = ({ myPage = 3, mySubpage = 1 }) => {
  const data = useStaticQuery(graphql`
      query {
          hero: file(name: { eq: "4.2HERO" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          video: file(
              name: { eq: "ADIDAS_DigitalCurriculum_Video8_9x16_CTM_190923_3bit" }
          ) {
              name
              publicURL
          }
          graphic1: file(name: { eq: "4.2a-ADIDAS-example" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic2: file(name: { eq: "4.2b-ADIDAS-graph" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic3: file(name: { eq: "4.2c-ADIDAS-brain" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `)

  const { hero, video, graphic1, graphic2, graphic3 } = data

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="The stress that one girl experiences can easily be manifested in an action that affects the entire team."
        image={hero}
        bg={"white"}
      />

      <InfoboxDetailed
        headerText={"Here’s an example"}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
      >
        <Flex flexDirection={["column", "column", "row"]} mt={6}>
          <Flex width={["100%", "100%", "50%"]}>
            <Box width={"100%"} mb={[5, 5, 0]} mt={4}>
              <Img
                fluid={graphic1.childImageSharp.fluid}
                margin={"0 auto"}
                loading={"eager"}
              ></Img>
            </Box>
          </Flex>
          <Box
            width={["100%", "100%", "50%"]}
            px={6}
            sx={{
              ">p": {
                marginBottom: 6,
              },
            }}
            textAlign={"left"}
          >
            <Text mt={0}>
              If Kara is mad at Rachel, she may not pass her the ball and she
              may not talk to her if she doesn’t have to. Eliana may become
              distracted after noticing that Kara is mad and miss her shot.
              What’s going on here?
            </Text>
            <Ul mb={6}>
              <li>
                Girls don’t usually compartmentalize their interactions and
                relationships, and because relationships matter to girls, they
                may take social conflicts onto the court or field.
                <Source>25</Source>
              </li>
              <li>
                Girls tend toward interpersonal sensitivity and may show more
                empathy and sensitivity to distress in others.
                <Source>26</Source>
              </li>
              <li>
                As a result, Kara, Rachel, and Eliana are all experiencing
                stress, and their individual experiences are being compounded on
                their team.
              </li>
            </Ul>
          </Box>
        </Flex>
      </InfoboxDetailed>

      <Infobox uppercase>
        On any given day, girls can experience all sorts of stress. Stress on
        its own isn’t necessarily good or bad; it’s just scaling up or down a
        spectrum from good stress to bad stress. It’s important for you as a
        coach to understand the way the brain works — and the way stress works
        on a spectrum — in order to respond to these stress reactions in a
        constructive way.
      </Infobox>

      <InfoboxDetailed
        headerText={
          <span>
            Not all stress is bad<Source>27</Source>
          </span>
        }
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
      >
        <Box width={"70%"} mb={[5, 0]} mt={6} mx={"auto"}>
          <Img
            fluid={graphic2.childImageSharp.fluid}
            margin={"0 auto"}
            loading={"eager"}
          ></Img>
        </Box>

        <Flex flexDirection={["column", "column", "row"]} mt={6}>
          <Flex width={["100%", "100%", "50%"]}>
            <Box pl={[0, , 0, 7]}>
              <Text
                uppercase
                fontSize={["18px", "24px"]}
                lineHeight={["27px", "36px"]}
                fontFamily={"AdineuePROBoldWeb"}
              >
                Good stress increases the stakes within your comfort zone.
              </Text>
              <Text>
                Imagine being at the free-throw line in basketball with the game
                on the line and the chance to win it for your team. You’ve
                practiced this shot hundreds of times and feel confident about
                your ability to perform. It's still a stressful moment, but you
                are ready!
              </Text>
            </Box>
          </Flex>
          <Flex width={["100%", "100%", "50%"]}>
            <Box pl={[0, 0, 7]}>
              <Text
                uppercase
                fontSize={["18px", "24px"]}
                lineHeight={["27px", "36px"]}
                fontFamily={"AdineuePROBoldWeb"}
              >
                Bad stress comes from situations that make you nervous and
                uncomfortable.
              </Text>
              <Text>
                Imagine having five seconds left on the clock of a basketball
                game, no more time-outs, and an extremely loud environment. You
                don’t have a play and haven’t prepared for this moment. The
                stress here is overwhelming.
              </Text>
            </Box>
          </Flex>
        </Flex>

        <Flex flexDirection={["column", "row"]} mt={6}>
          <Box width={["100%", "50%"]}>
            <Box width={"100%"} maxWidth={["253px", "337px"]} mx={"auto"}>
              <CustomPlayer url={video.publicURL} videoLabel={video.name} vertical></CustomPlayer>
            </Box>
          </Box>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            width={["100%", "50%"]}
            px={6}
            sx={{
              ">p": {
                marginBottom: 6,
              },
            }}
            textAlign={"center"}
          >
            <Text
              uppercase
              fontSize={["20px", "24px", "32px"]}
              lineHeight={["30px", "34px", "48px"]}
              fontFamily={"AdineuePROBoldWeb"}
            >
              In this moment, the player was not thinking; she was reacting to a
              stressful and frustrating situation. Humans are wired to react to
              stress. We have an unconscious, biological reaction when stress
              gets the best of us.
            </Text>
          </Flex>
        </Flex>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={"Not all stress is bad"}
        maxWidth={"100%"}
        bg={"seafoam"}
        headerBackground={"transparent"}
        contentMaxWidth={"desktop"}
      >
        <Box bg={"white-panel"} mt="5" px={[5, 5, 6]} py={[6, 6]}>
          <Flex
            flexDirection={["column-reverse", "column-reverse", "row"]}
            mt={4}
          >
            <Box
              width={["100%", "100%", "50%"]}
              px={6}
              sx={{
                ">p": {
                  marginBottom: 6,
                },
              }}
              textAlign={"left"}
            >
              <Text mt={0}>
                The brain develops from the back to the front. Let’s talk about
                the BRAIN STEM.
              </Text>
              <Text>
                The brain stem is the part of the brain at the back, near where
                the head and neck meet. It controls everything we do that is
                unconscious, such as breathing, swallowing, and blinking.
              </Text>
              <Text>
                At the other side of the brain is the PREFRONTAL CORTEX. This is
                the part of the brain that makes you you. It sits behind your
                forehead and is responsible for rational thought and critical
                decision-making. Another way to think about it is like a
                thermostat. It controls and moderates things in your brain to
                make sure things are OK. <Source>28</Source>
              </Text>
              <Text>
                Next is the tiny but important AMYGDALA. The amygdala sits right
                between the brain stem and the prefrontal cortex. It has one
                job: to be on the lookout for threats or stressors. Think about
                it like a smoke detector, it looks for danger and reacts to it.
                <Source>29</Source>
              </Text>
              <Text>
                When we are calm, we are operating in our prefrontal cortex.
                When we are experiencing stress, we activate our amygdala.
                Ideally, we want to spend most of our lives with the prefrontal
                cortex in charge and not the amygdala, but let’s see what
                happens when this isn’t the case.
              </Text>
            </Box>
            <Flex
              width={["95%", "95%", "50%"]}
              alignItems={"center"}
              mx={"auto"}
            >
              <Box width={"100%"} mb={[5, 5, 0]} mt={4}>
                <Img
                  fluid={graphic3.childImageSharp.fluid}
                  margin={"0 auto"}
                  loading={"eager"}
                ></Img>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </InfoboxDetailed>

      <Copyright bg={"seafoam"}/>
    </Layout>
  )
}

export default withProgress(Page, 3, 1)
